import React, { Fragment } from 'react'
import styles from './Button.module.scss'

export default ({ variant = 'primary', href, external, size, children }) => {
  return (
    <a
      href={href}
      className={`${styles.button} ${variant === 'secondary' &&
        styles.secondary} ${size === 'xl' && styles.xl}`}
      target={external ? '_blank' : ''}
    >
      {children}
    </a>
  )
}
