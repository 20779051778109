import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import Logo from './Logo'
import styles from './Nav.module.scss'
import Button from './Button'

const Navigation = () => {
  const [currentPath, setCurrentPath] = useState(null)
  const [active, setActive] = useState(false)
  const [flyout, setFlyout] = useState('none')
  const [yPosition, setYPosition] = useState(0)
  const ref = useRef(null)
  const [mobileActive, setMobileActive] = useState(false)

  useEffect(() => {
    setCurrentPath(window.location.pathname)
  }, [])

  // const handleMenuToggle = () => setActive(!active)
  const handleMenuToggle = () => setActive(!active)

  const handleMobileToggle = () => setMobileActive(!mobileActive)

  const handleLinkClick = () => [active && handleMenuToggle()]

  const handleFlyout = value => {
    setFlyout(value)
    {
      !active && setActive(true)
    }
  }

  const NavLink = ({ to, className, children, ...props }) => (
    <Link
      to={to}
      className={className}
      onClick={active && handleLinkClick}
      {...props}
    >
      {children}
    </Link>
  )

  const useOutsideAlerter = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFlyout('none')
          if (window.pageYOffset === 0) {
            setActive(false)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(ref)

  const isBrowser = () => typeof window !== 'undefined'

  if (isBrowser()) {
    window.onscroll = function() {
      scrollFunction()
    }
  }

  const scrollFunction = () => {
    if (window.pageYOffset > 0) {
      setActive(true)
    } else {
      if (flyout === 'none') {
        setActive(false)
      }
    }
  }

  return !mobileActive ? (
    <div ref={ref}>
      <nav
        className={`${styles.nav} ${active && styles.active} ${mobileActive &&
          styles.active}`}
      >
        <div className={styles.navContainer}>
          <Link to="/" onClick={handleLinkClick}>
            <Logo />
          </Link>

          <div className={`${styles.rightContainer} ${styles.hideMobile}`}>
            <NavLink
              to="/"
              className={`${styles.navLink} ${'/work' === currentPath &&
                styles.active}`}
            >
              Work
            </NavLink>
            <NavLink
              to="/stories"
              className={`${styles.navLink} ${'/stories' === currentPath &&
                styles.active}`}
            >
              Stories
            </NavLink>
          </div>
          <button
            className={styles.menuButton}
            onClick={() => handleMobileToggle()}
          >
            {active ? (
              mobileActive ? (
                <X />
              ) : (
                <Menu />
              )
            ) : mobileActive ? (
              <X />
            ) : (
              <Menu />
            )}
          </button>
        </div>
      </nav>
    </div>
  ) : (
    <div>
      <nav className={`${styles.nav} ${mobileActive && styles.active}`}>
        <div className={styles.navContainer}>
          <Link to="/" onClick={handleLinkClick}>
            {flyout != 'none' || active ? (
              mobileActive ? (
                <Logo />
              ) : (
                <Logo />
              )
            ) : mobileActive ? (
              <Logo />
            ) : (
              <Logo />
            )}
          </Link>
          <div className={styles.navLinks}>
            <NavLink
              to="/"
              className={`${styles.navLink} ${'/' === currentPath &&
                styles.active}`}
            >
              Work
            </NavLink>
            <NavLink
              to="/stories"
              className={`${styles.navLink} ${'/stories' === currentPath &&
                styles.active}`}
            >
              Stories
            </NavLink>
          </div>
          <button
            className={styles.menuButton}
            onClick={() => handleMobileToggle()}
          >
            {active ? (
              mobileActive ? (
                <X />
              ) : (
                <Menu />
              )
            ) : mobileActive ? (
              <X />
            ) : (
              <Menu />
            )}
          </button>
        </div>
      </nav>
    </div>
  )
}

export default Navigation
