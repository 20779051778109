import React, { useEffect, useState } from 'react'
import styles from './Footer.module.scss'

export default () => {
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div>
            <p>Based in Oslo, Norway</p>
            <p>Local time – {time.toLocaleTimeString()}</p>
          </div>
          <div />
          <div>
            <a href="https://twitter.com/VegardLoewe" target="_blank">
              Twitter
            </a>
            <a href="https://www.linkedin.com/in/vegard-lowe/" target="_blank">
              LinkedIn
            </a>
          </div>
          <div>
            <a href="https://www.behance.net/VegardLoewe" target="_blank">
              Behance
            </a>
            <a href="https://github.com/vegardloewe" target="_blank">
              Github
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
